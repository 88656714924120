import React, { useState } from "react";
import { AppHeaderWrapper, AppNavbarWrapper, HeaderTitle, NavbarLink, NavbarLinkGroup } from "./applayout.styles";

import metamaskImg from "../../assets/image/metamask.svg";
import logo from "../../assets/image/token.png";
import InPageConnectButton from "../rainbow-button/index";
import { DEFAULD_NETWORK, getAddresses } from "src/constants";
import { useNetwork } from 'wagmi'
import { Link } from "react-router-dom";



const AppHeader: React.FC = () => {

    const { chain } = useNetwork();

    const addTokenToWallet = (tokenAddress: string) => async () => {

        const host = window.location.origin;
        const tokenImgUrl = `${host}/${logo}`;

        if (window.ethereum && chain && chain.id == DEFAULD_NETWORK) {
            try {

                await window.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: tokenAddress,
                            symbol: 'SVC',
                            decimals: 18,
                            image: tokenImgUrl,
                        },
                    },
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const tokenAddress = getAddresses(DEFAULD_NETWORK);

    return (
        <>
            <AppHeaderWrapper id="header">
                <AppNavbarWrapper>
                    <Link to='/'>
                        <HeaderTitle>SVC Protocol</HeaderTitle>
                    </Link>
                    <NavbarLinkGroup>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open('https://legacy.quickswap.exchange/#/swap?outputCurrency=0x9aA68BA3746D05009135D5f33D3Ced069dcA719b', '_blank')}
                        >
                            <NavbarLink active={true}> Buy SVC</NavbarLink>
                        </div>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={addTokenToWallet(tokenAddress.SVC_ADDRESS)}
                        >
                            <NavbarLink>
                                +<img src={metamaskImg} alt="metamaskImg" />
                            </NavbarLink>
                        </div>
                    </NavbarLinkGroup>
                </AppNavbarWrapper>
                <InPageConnectButton />
            </AppHeaderWrapper>
        </>
    );
};

export default AppHeader;
