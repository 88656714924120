import { Networks } from "./blockchain";

const MATIC_MAINNET = {
    SVC_ADDRESS: "0xFd5b89c3Db6E384C19fEAcb3eE10a47E163f12E5",
    DAO_ADDRESS: "0xF7994FbB2c4D819f2755a79Db330023481BfB541",
    USDT_ADDRESS: "0x6f025B7495bD8604a358E438927Bf76aA03F27D4"
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.MATIC) return MATIC_MAINNET;
    throw Error("Network don't support");
};
